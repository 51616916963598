<!-- pop -->
<template>
  <el-popover
    placement="top"
    width="300"
    @show="show"
    @hide="hide"
    v-model="popShow"
  >
    <p class="tatle"><span>*</span>{{$t('front-project-module-v1-actual_working_hours')}}</p>
    <el-input 
      size="small"
      style="margin:5px 0px"
      v-model="workhour"
      @blur="blurinput"
      @keyup.native="proving($event)"
    >
    </el-input>
    <el-input
    type="textarea"
    :rows="4"
    :autosize="{
      minRows: 4,
      maxRows: 8,
    }"
    :placeholder="$t('label.tabpage.contentz')"
    v-model="value">
    </el-input>
    <div style="text-align: right; margin-top:10px ">
      <!-- 取消 -->
      <el-button size="mini" type="text" @click="handleCancle">{{$t("label.cancel")}}</el-button>
      <!-- 确定 -->
      <el-button type="primary" size="mini" @click="handleOk">{{$t("label.chatter.ok")}}</el-button>
    </div>
    <el-tooltip :content="$t('c515')" placement="top" slot="reference"  >
    <!-- <i class="el-icon-document"></i> -->
     <svg class="icon"  aria-hidden="true">
        <use :href="blueorff?'#icon-knowledgeBase_copyCase_cli':'#icon-knowledgeBase_copyCase_def'"></use>
      </svg>
    </el-tooltip>
  </el-popover>
</template>
<script>
export default {
  name: "pop",
  components: {},
  data() {
    return {
      popShow: false,
      value:'',
      workhour:null,
      blueorff:false,
    };
  },
  props: {
    remark:{
      type:String,
      default:'',
    },
    workhours:{
      type:Number,
      default:null,
    },
    tableindex:{
      type:Number,
      default:null,
    },
    index:{
      type:Number,
      default:null,
    }
  },
  computed: {},
  watch: {
    remark(){
      this.value=this.remark
    },
    workhours(){
      this.workhour=this.workhours;
    }
  },
  methods: {
    //失去焦点
    blurinput(){
      if (this.workhour&&this.workhour!==''&&this.workhour!==null) {
        let xiaoshu = this.workhour.split(".")[1];
        if ((xiaoshu&&xiaoshu.length == 0) || !xiaoshu) {
          this.workhour=this.workhour.split(".")[0]
        }
      }
    },
    //验证工时
    proving(e) {
      // 正数，最多一位小数的正数
      const reg = /^(([1-9]{1}\d*)|([0]{1}))(\.(\d){0,2})?$/;
      // const reg = /^(([1-9]{1}\d*))?$/;
      if (!reg.test(e.target.value)) {
        this.$message.warning(this.$i18n.t('label.field.input.number'));//"请输入最多一位小数的数字"
        this.workhour='' //输入错误清空
      }
      if (e.target.value && (e.target.value > 24||e.target.value<0)) {
        this.$message.warning(this.$i18n.t('label.field.input.number'));//请输入0-24之间的正整数
        this.workhour='' //输入错误清空
      }
    },
    hide() {
      this.$emit('showpop',this.tableindex,this.index,true,'even')
      this.popShow = false;
    },
    show() {
      this.$emit('showpop',this.tableindex,this.index,false)
      this.popShow = true;
    },
    handleOk(){
      if(this.workhour&&this.workhour!==''&&this.workhour!==null){
        if(this.value!==''){
          this.blueorff=true;
        }else{
          this.blueorff=false;
        }
        this.$emit("updateremark",this.value,this.workhour,this.tableindex,this.index)
        this.popShow = false;
      }else{
        this.$message.error(this.$i18n.t('front-project-module-v1-input-actual_working_hours'))
      }
      
    },
    handleCancle(){
      // this.value = '';
      this.$emit('showpop',this.tableindex,this.index,true,'even')
      this.popShow = false;
    }
  },
  created() {},
  mounted() {},
};
</script>
<style lang='scss' scoped>
.tatle{
  font-size: 12px;
  line-height: 18px;
  span{
    color:red;
    padding-right: 5px;
  }
}
.icon{
  width: 12px;
  height: 12px;

}
</style>