<!-- CreatProObjChild -->
<!-- 开发人员：马金祥 -->
<template>
  <div class="mainPro">
    <div class="topDate" role="1">
      <el-tooltip
        class="item"
        effect="dark"
        :content="$t('label.appointmentpage.wizard.subtitle1-2')"
        placement="top-start"
      >
        <span @click="preWeek" class="el-icon-arrow-left checkIcon"></span>
      </el-tooltip>
      <div class="weekblock">
        <el-date-picker
          class="weekDate"
          type="week"
          :editable="false"
          v-model="gradeDate"
          format="yyyy年第WW周"
          @change="toWeek(gradeDate)"
          :picker-options="weekDateOption"
        ></el-date-picker>
        <el-input
          class="week-picker"
          v-model="weekTime"
          prefix-icon="el-icon-date"
          placeholder="请选择"
        ></el-input>
      </div>
      <el-tooltip
        class="item"
        effect="dark"
        :content="$t('label.appointmentpage.wizard.subtitle1-1')"
        placement="top-start"
      >
        <span @click="nextWeek" class="el-icon-arrow-right checkIcon"></span>
      </el-tooltip>
    </div>

    <div class="proMain">
      <div class="proCon">
        <div class="proList">
          <!-- 头 -->
          <ul class="topHead">
            <div v-for="(item, index) in tableColumns" :key="index">
              <li v-if="item.isCheckDel"><span></span></li>
              <li v-if="item.isForm" class="formItem">
                <span>{{ item.label }}</span>
              </li>
              <li
                v-if="
                  !item.isCheckDel &&
                  !item.isForm &&
                  !item.isCheckBox &&
                  !item.isTotal
                "
                class="special-column"
              >
                <span>{{ getweek(item) }}</span>
              </li>
              <li v-if="item.isCheckBox" class="checkItem">
                <span>{{ item.label }}</span>
              </li>
              <li v-if="item.isTotal">
                <span>{{ item.label }}</span>
              </li>
            </div>
          </ul>
          <!-- 内容 -->
          <div class="tableConBox">
            <ul
              v-for="(tableItem, tableIndex) in tableData"
              :key="tableIndex"
              class="tableCon"
            >
              <div v-for="(item, index) in tableItem" :key="index">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="$t('label.tabpage.rowsz')"
                  placement="top"
                >
                  <li
                    v-if="item.isCheckDel"
                    class="removeIcon el-icon-remove-outline"
                    @click="handleRemove(tableIndex)"
                  ></li>
                </el-tooltip>
                <li v-if="item.isForm" class="formItem">
                  <b>*</b>
                  <span>
                    <el-select
                      v-if="item.type === 'remote-select'"
                      v-model="item[item.prop]"
                      filterable
                      :placeholder="$t('label.searchs')"
                      :multiple="false"
                      clearable
                      remote
                      :remote-method="
                        (query) => remoteMethod(query, item, index, tableIndex)
                      "
                      :loading="loading"
                      class="no-suffix"
                      style="width: 100%"
                      @change="
                        changeEvent(item[item.prop], tableIndex, item.prop)
                      "
                      @focus="selectFocus"
                    >
                      <p class="searchTipBox">
                        <span
                          @click="remoteSearch(item, tableIndex, index)"
                          style="display: inline-block; width: 100%"
                        >
                          <i class="el-icon-search"></i>
                          <span style="padding-left: 10px">
                            {{
                              $t(
                                "vue_label_commonobjects_detail_to_precise_search"
                              )
                            }}</span
                          >
                        </span>
                      </p>
                      <!-- 占位 -->
                      <el-option
                        v-show="false"
                        key="new"
                        :label="$t('label.new')"
                        value="新建"
                      ></el-option>
                      <el-option
                        v-for="itm in item.optionList"
                        :key="itm.value"
                        :label="itm.label"
                        :value="itm.value"
                      ></el-option>
                      <p class="searchTipBox" @click="newSearchableField(item)">
                        <i class="el-icon-plus"></i>
                        <span style="padding-left: 10px">
                          <!-- 新建 -->
                          {{ $t("label.new") }}
                        </span>
                      </p>
                    </el-select>
                    <SearchRemoteTab
                      ref="searchRemoteTab"
                      class="searchRemoteTab"
                      v-if="item.type === 'search-remote-tab'"
                      :operationColum="tableIndex"
                      :project_name="formData[tableIndex]['project_name']"
                      :projectDataType.sync="item.projectDataType"
                      :taskOrProblem.sync="item.taskOrProblem"
                      :member="formData[tableIndex]['member']"
                      @checkSearchTab="updateSearchTab"
                    />
                  </span>
                </li>
                <li
                  v-if="
                    !item.isCheckDel &&
                    !item.isForm &&
                    !item.isCheckBox &&
                    !item.isTotal
                  "
                  class="special-column"
                >
                  <span
                    @mouseenter="iconmouseenter(tableIndex, index)"
                    @mouseleave="iconmouseleave(tableIndex, index)"
                  >
                    <Pop
                      class="popInner"
                      v-show="
                        (item.isconshow || item.actual_working_hours) &&
                        !item.disabled
                      "
                      :remark.sync="item.remarks"
                      :workhours="item.actual_working_hours"
                      :tableindex="tableIndex"
                      :index="index"
                      @updateremark="updateremark"
                      @showpop="showpop"
                    />
                    <el-input
                      :key="'input' + index"
                      v-model="item.actual_working_hours"
                      :disabled="item.disabled"
                      @blur="blurinput(index, tableIndex)"
                      @keyup.native="proving($event, item, index, tableIndex)"
                    ></el-input>
                  </span>
                </li>
                <li v-if="item.isCheckBox" class="checkItem">
                  <span>
                    <el-checkbox v-model="item.ischecked"></el-checkbox>
                  </span>
                </li>
                <li v-if="item.isTotal" class="isTotal">
                  <span>{{ item.value.toFixed(2) }}</span>
                </li>
              </div>
            </ul>
          </div>
          <!-- 尾 -->
          <ul class="topBottom">
            <div v-for="(item, index) in tableBottom" :key="index">
              <li v-if="item.isnull"><span></span></li>
              <li v-if="item.isTotal">
                <span>{{ item.value.toFixed(2) }}</span>
              </li>
              <li v-if="item.isChecknull"><span></span></li>
              <li v-if="item.isAllTotal">
                <span>{{ item.value.toFixed(2) }}</span>
              </li>
            </div>
          </ul>
        </div>
      </div>
    </div>
    <el-button
      type="primary"
      plain
      size="mini"
      @click="addLine"
      class="addLine"
      >{{ $t("addrow") }}</el-button
    >
    <div style="text-align: right" class="bottomBtnBox">
      <el-button size="mini" @click="cancel">
        <!-- 取消 -->
        {{ $t("button_partner_cancel") }}
      </el-button>
      <el-button
        size="mini"
        v-preventReClick
        :loading="isSaveBtnLoadin"
        @click="save('saveAndRenew')"
      >
        <!-- 保存并新建 -->
        {{ $t("label.saveandnew") }}
      </el-button>
      <el-button
        size="mini"
        type="primary"
        v-preventReClick
        @click="save"
        :loading="isSaveBtnLoadin"
      >
        <!-- 保存 -->
        {{ $t("label.save") }}
      </el-button>
    </div>
    <!-- 查找/查找多选 -->
    <el-dialog
      :title="$t('label.searchs')"
      width="60%"
      top="5%"
      :visible.sync="showSearchTable"
      :lock-scroll="true"
      :close-on-click-modal="false"
      :modal="true"
      :before-close="beforeClose"
      append-to-body
    >
      <div
        :style="{
          'min-height': dialogBodyHeight,
          overflow: 'auto',
          padding: '0 20px',
        }"
      >
        <search-table
          ref="searchTable"
          :dialogBodyHeight="dialogBodyHeight"
          :fieldId="fieldId"
          :checked="checked"
          :relevant-objid="relevantObjId"
          :relevant-objapi="relevantObjApi"
          :relevant-prefix="relevantPrefix"
          :projectId="projectId"
          :taskOrquestion="taskProblem"
          @changeSelect="changeSelect"
          @setFieldReltaion="setFieldReltaion"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as CommonObjApi from "../api";
import SearchTable from "@/components/Form/search-table.vue";
import Pop from "./pop.vue";
import SearchRemoteTab from "@/components/Form/SearchRemoteTab.vue";
import Bus from "@/mixin/bus.js";
import _ from "lodash";

export default {
  name: "CreatProObjChild",
  components: { SearchTable, Pop, SearchRemoteTab },
  props: {
    task: {
      type: Object,
      default: () => {},
    },
    formAttr: {
      type: Array,
    },
  },
  data() {
    return {
      tableData: [],
      gradeDate: new Date(),
      formData: [], //每行的项目id，选择任务/问题时使用
      optionList: {}, // 远程搜索下拉数据
      firstNum: 1, //可以根据中国1或者国外7更改从周几开始
      loading: false, // 远程搜索loading
      fieldId: "",
      checked: false,
      relevantObjId: "",
      relevantObjApi: "",
      relevantPrefix: "",
      newObjLevel: 0, // 新建查找字段标识
      recordId: "", //主从记录ID
      showSearchTable: false,
      dialogBodyHeight: `${document.body.offsetHeight - 248}px`,
      tableColumns: [],
      timeList: [],
      popShow: false,
      weekTime: "",
      tableBottom: [],
      curColum: "", //当前列
      curRow: "", //当前行
      curProp: "project_name", //当前是哪个下拉对象
      projectId: "",
      weekDateOption: {
        //周选择器禁止选择未来日期
        firstDayOfWeek: this.firstNum,
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      isSaveBtnLoadin: false, // 保存按钮loading
      taskProblem: "", //任务、问题id
      aa: true,
    };
  },
  computed: {},
  watch: {
    tableData: {
      handler(val) {
        if (val.length) {
          let rowSum = 0,
            sum1 = 0,
            sum2 = 0,
            sum3 = 0,
            sum4 = 0,
            sum5 = 0,
            sum6 = 0,
            sum7 = 0;
          for (let i in val) {
            // 行计算
            for (let j in val[i]) {
              if (val[i][j].actual_working_hours) {
                rowSum += val[i][j].actual_working_hours * 1;
              }
            }
            this.tableData[i][12].value = rowSum;
            //下一行开始循环前先重置累加和为0
            rowSum = 0;
            // 列计算
            let columnsub = [4, 5, 6, 7, 8, 9, 10];
            for (let j of columnsub) {
              switch (j) {
                case 4:
                  sum1 += val[i][4].actual_working_hours * 1;
                  break;
                case 5:
                  sum2 += val[i][5].actual_working_hours * 1;
                  break;
                case 6:
                  sum3 += val[i][6].actual_working_hours * 1;
                  break;
                case 7:
                  sum4 += val[i][7].actual_working_hours * 1;
                  break;
                case 8:
                  sum5 += val[i][8].actual_working_hours * 1;
                  break;
                case 9:
                  sum6 += val[i][9].actual_working_hours * 1;
                  break;
                case 10:
                  sum7 += val[i][10].actual_working_hours * 1;
                  break;

                default:
                  break;
              }
            }
          }
          this.tableBottom[4].value = sum1;
          this.tableBottom[5].value = sum2;
          this.tableBottom[6].value = sum3;
          this.tableBottom[7].value = sum4;
          this.tableBottom[8].value = sum5;
          this.tableBottom[9].value = sum6;
          this.tableBottom[10].value = sum7;
          this.tableBottom[12].value =
            sum1 + sum2 + sum3 + sum4 + sum5 + sum6 + sum7;
        }
      },
      deep: true,
    },
  },
  filters: {},
  methods: {
    showpop(tableIndex, index, val, even) {
      this.tableData[tableIndex][index].iconsh = val;
      if (even && even == "even") {
        this.iconmouseleave(tableIndex, index);
      }
    },
    //工作总结
    updateremark(remark, value, tableindex, index) {
      this.tableData[tableindex][index].actual_working_hours = value;
      this.tableData[tableindex][index].remarks = remark;
      let obj = this.tableData[tableindex][index];

      this.$set(this.tableData[tableindex], index, obj);
    },
    //工时内容输入框滑入
    iconmouseenter(tableIndex, index) {
      this.tableData[tableIndex][index].isconshow = true;
      this.tableData[tableIndex][index].iconsh = true;
      let obj = this.tableData[tableIndex][index];
      this.$set(this.tableData[tableIndex], index, obj);
    },
    //工时内容输入框滑出
    iconmouseleave(tableIndex, index) {
      if (this.tableData[tableIndex][index].iconsh) {
        this.tableData[tableIndex][index].isconshow = false;
        let obj = this.tableData[tableIndex][index];
        this.$set(this.tableData[tableIndex], index, obj);
      }
    },
    // select获取焦点--隐藏任务/问题弹窗
    selectFocus() {
      const domCom = this.$refs.searchRemoteTab;
      domCom.forEach((item, index) => {
        if (item.visible) {
          this.$refs.searchRemoteTab[index].visible = false;
        }
      });
    },
    // 点击删除
    handleRemove(index) {
      if (index != 0) {
        this.tableData.splice(index, 1);
        this.formData.splice(index, 1);
      } else {
        this.$message.warning(
          this.$i18n.t("label.projectManagement.one.work.list")
        );
      }
    },
    /* 添加行 */
    addLine() {
      let i = this.tableData.length;
      if (i > 4) {
        this.$message.warning(this.$i18n.t("c128"));
        return;
      }
      this.formData.push({ project_name: "" });
      this.tableData.push([
        { isCheckDel: true },
        {
          prop: "project_name",
          label: this.$i18n.t("label.projectManagement.entryname"), //"项目名称"
          fieldId: "ffe20worklistp1I11",
          objId: "cloudcc_project",
          prefix: "p01",
          isForm: true,
          type: "remote-select",
          optionList: [],
          project_name: "",
        },
        {
          prop: "member",
          label: this.$i18n.t("label.marketseamemset.user"),
          fieldId: "ffe20worklistp1I15",
          objId: "cloudccprojectmember",
          prefix: "p11",
          isForm: true,
          type: "remote-select",
          optionList: [],
          member: "",
        },
        {
          prop: "taskOrProblem",
          label: this.$i18n.t("label.projectManagement.Taskorproblem"), //任务/问题
          fieldId: "ffe20worklistp1I23",
          objId: "cloudccTask",
          prefix: "p03",
          isForm: true,
          type: "search-remote-tab",
          optionList: [],
          projectDataType: "",
          taskOrProblem: "",
        },
        {
          key: i,
          prop: "week" + i,
          week: i + 1,
          actual_working_hours: "",
          remarks: "",
          disabled:
            new Date(this.timeList[0]).getTime() > Date.now() ? true : false,
          duedate: this.timeList[0],
        },
        {
          key: i,
          prop: "week" + i,
          week: i + 1,
          actual_working_hours: "",
          remarks: "",
          disabled:
            new Date(this.timeList[1]).getTime() > Date.now() ? true : false,
          duedate: this.timeList[1],
        },
        {
          key: i,
          prop: "week" + i,
          week: i + 1,
          actual_working_hours: "",
          remarks: "",
          disabled:
            new Date(this.timeList[2]).getTime() > Date.now() ? true : false,
          duedate: this.timeList[2],
        },
        {
          key: i,
          prop: "week" + i,
          week: i + 1,
          actual_working_hours: "",
          remarks: "",
          disabled:
            new Date(this.timeList[3]).getTime() > Date.now() ? true : false,
          duedate: this.timeList[3],
        },
        {
          key: i,
          prop: "week" + i,
          week: i + 1,
          actual_working_hours: "",
          remarks: "",
          disabled:
            new Date(this.timeList[4]).getTime() > Date.now() ? true : false,
          duedate: this.timeList[4],
        },
        {
          key: i,
          prop: "week" + i,
          week: i + 1,
          actual_working_hours: "",
          remarks: "",
          disabled:
            new Date(this.timeList[5]).getTime() > Date.now() ? true : false,
          duedate: this.timeList[5],
        },
        {
          key: i,
          prop: "week" + i,
          week: i + 1,
          actual_working_hours: "",
          remarks: "",
          disabled:
            new Date(this.timeList[6]).getTime() > Date.now() ? true : false,
          duedate: this.timeList[6],
        },
        { isCheckBox: true, ischecked: false, settlement_type: "否" },
        { isTotal: true, value: 0 },
      ]);
    },
    beforeClose() {
      this.showSearchTable = false;
    },
    //获取某个日期的前几天
    getbeforWeek(time, number) {
      const num = number;
      const date = time;
      let mon = date.getMonth() + 1;
      let day = date.getDate();
      if (day <= num) {
        if (mon > 1) {
          mon = mon - 1;
        } else {
          mon = 12;
        }
      }
      date.setDate(date.getDate() - num);
      return date;
    },
    //获取某个日期的后几天
    getlastWeek(time, number) {
      const num = number;
      const date = time;
      let mon = date.getMonth() + 1;
      let day = date.getDate();
      if (day <= num) {
        if (mon > 1) {
          mon = mon - 1;
        } else {
          mon = 12;
        }
      }
      date.setDate(date.getDate() + num);
      return date;
    },
    // 前一周
    preWeek() {
      this.toWeek(this.$moment(this.gradeDate).subtract(1, "weeks"));
      this.gradeDate = this.$moment(this.gradeDate).subtract(1, "weeks");
    },
    // 下一周
    nextWeek() {
      this.toWeek(this.$moment(this.gradeDate).subtract(-1, "weeks"));
      this.gradeDate = this.$moment(this.gradeDate).subtract(-1, "weeks");
    },
    getweek(item) {
      let val = item.week;
      switch (val) {
        case 1:
          return this.$i18n.t("monday") + item.duedate;
        case 2:
          return this.$i18n.t("tuesday") + item.duedate;
        case 3:
          return this.$i18n.t("wednesday") + item.duedate;
        case 4:
          return this.$i18n.t("thursday") + item.duedate;
        case 5:
          return this.$i18n.t("friday") + item.duedate;
        case 6:
          return this.$i18n.t("saturday") + item.duedate;
        case 7:
          return this.$i18n.t("sunday") + item.duedate;
      }
    },
    formatStr(str) {
      return (str = str < 10 ? "0" + str : str);
    },
    //监听点击日期对应周几
    toWeek(time) {
      this.tableData = [];
      this.formData = [];
      let weekData = [];
      let ji = new Date(time).getDay(); //将日期转换成周
      let date = new Date(time); //指定在对象中存储的日期
      this.timeList = [];
      for (let i = 1 - ji; i < 8 - ji; i++) {
        //根据选定的星期进行循环一周
        let q = new Date(); //获取当前日期
        let tt;
        if (this.firstNum === 1) {
          tt = q.setTime(date.getTime() + 3600 * 1000 * 24 * i); //中国处理：将当前日期设置成指定日期的前i天的毫秒日期
        } else {
          tt = q.setTime(date.getTime() + 3600 * 1000 * 24 * (i - 1)); //国外处理：将当前日期设置成指定日期的前i天的毫秒日期
        }
        let td = new Date(tt); //将获取到的毫秒日期指定到对象中存储
        let time =
          td.getFullYear() +
          "-" +
          this.formatStr(td.getMonth() + 1) +
          "-" +
          this.formatStr(td.getDate()); //将毫秒日期转为年月日格式
        this.timeList.push(time); //将根据选择的日期循环出来一周的日期放进数组里面
      }
      this.weekTime = this.timeList[0] + "-" + this.timeList[6];
      if (this.firstNum === 1) {
        for (let i = 0; i < 7; i++) {
          // 中国
          weekData.push({
            key: i,
            prop: "week" + i,
            week: i + 1,
            actual_working_hours: "",
            duedate: this.timeList[i].substring(8, 10),
          });
        }
      } else {
        for (var i = 0; i < 7; i++) {
          // 国外
          if (i === 0) {
            weekData.push({
              key: i,
              prop: "week" + i,
              week: 7,
              actual_working_hours: "",
              duedate: this.timeList[i].substring(8, 10),
            });
          } else {
            weekData.push({
              key: i,
              prop: "week" + i,
              week: i,
              actual_working_hours: "",
              duedate: this.timeList[i].substring(8, 10),
            });
          }
        }
      }

      // 表头数据
      this.tableColumns = [
        { isCheckDel: true },
        {
          prop: "project_name",
          label: this.$i18n.t("label.projectManagement.entryname"), //"项目名称"
          fieldId: "ffe20worklistp1I11",
          objId: "cloudcc_project",
          prefix: "p01",
          isForm: true,
          type: "remote-select",
          optionList: [],
          project_name: "",
        },
        {
          prop: "member",
          label: this.$i18n.t("label.marketseamemset.user"), //成员
          fieldId: "ffe20worklistp1I15",
          objId: "cloudccprojectmember",
          prefix: "p11",
          isForm: true,
          type: "remote-select",
          optionList: [],
          member: "",
        },
        {
          prop: "taskOrProblem",
          label: this.$i18n.t("label.projectManagement.Taskorproblem"), //任务/问题
          fieldId: "ffe20worklistp1I23",
          objId: "cloudccTask",
          prefix: "p03",
          isForm: true,
          type: "search-remote-tab",
          optionList: [],
          projectDataType: "",
          taskOrProblem: "",
        },
        ...weekData,
        {
          isCheckBox: true,
          label: this.$i18n.t("label.projectManagement.charging"),
        }, //计费
        {
          isTotal: true,
          label: this.$i18n.t("label.projectManagement.allhours"),
        }, //全部(小时)
      ];
      // 表格底部统计
      this.tableBottom = [
        { isnull: true },
        { isnull: true },
        { isnull: true },
        { isnull: true },
        { isTotal: true, value: 0 },
        { isTotal: true, value: 0 },
        { isTotal: true, value: 0 },
        { isTotal: true, value: 0 },
        { isTotal: true, value: 0 },
        { isTotal: true, value: 0 },
        { isTotal: true, value: 0 },
        { isChecknull: true },
        { isAllTotal: true, value: 0 },
      ];
      // 表格内容区默认数据
      for (let i = 0; i < 5; i++) {
        this.formData.push({ project_name: "" });
        this.tableData.push([
          { isCheckDel: true },
          {
            prop: "project_name",
            label: this.$i18n.t("label.projectManagement.entryname"), //"项目名称",
            fieldId: "ffe20worklistp1I11",
            objId: "cloudcc_project",
            prefix: "p01",
            isForm: true,
            type: "remote-select",
            optionList: [],
            project_name: "",
          },
          {
            prop: "member",
            label: this.$i18n.t("label.marketseamemset.user"),
            fieldId: "ffe20worklistp1I15",
            objId: "cloudccprojectmember",
            prefix: "p11",
            isForm: true,
            type: "remote-select",
            optionList: [],
            member: "",
          },
          {
            prop: "taskOrProblem",
            label: this.$i18n.t("label.projectManagement.Taskorproblem"), //任务/问题
            fieldId: "ffe20worklistp1I23",
            objId: "cloudccTask",
            prefix: "p03",
            isForm: true,
            type: "search-remote-tab",
            optionList: [],
            projectDataType: "",
            taskOrProblem: "",
          },
          {
            key: i,
            prop: "week" + i,
            week: i + 1,
            actual_working_hours: "",
            remarks: "",
            disabled:
              new Date(this.timeList[0]).getTime() > Date.now() ? true : false,
            duedate: this.timeList[0],
          },
          {
            key: i,
            prop: "week" + i,
            week: i + 1,
            actual_working_hours: "",
            remarks: "",
            disabled:
              new Date(this.timeList[1]).getTime() > Date.now() ? true : false,
            duedate: this.timeList[1],
          },
          {
            key: i,
            prop: "week" + i,
            week: i + 1,
            actual_working_hours: "",
            remarks: "",
            disabled:
              new Date(this.timeList[2]).getTime() > Date.now() ? true : false,
            duedate: this.timeList[2],
          },
          {
            key: i,
            prop: "week" + i,
            week: i + 1,
            actual_working_hours: "",
            remarks: "",
            disabled:
              new Date(this.timeList[3]).getTime() > Date.now() ? true : false,
            duedate: this.timeList[3],
          },
          {
            key: i,
            prop: "week" + i,
            week: i + 1,
            actual_working_hours: "",
            remarks: "",
            disabled:
              new Date(this.timeList[4]).getTime() > Date.now() ? true : false,
            duedate: this.timeList[4],
          },
          {
            key: i,
            prop: "week" + i,
            week: i + 1,
            actual_working_hours: "",
            remarks: "",
            disabled:
              new Date(this.timeList[5]).getTime() > Date.now() ? true : false,
            duedate: this.timeList[5],
          },
          {
            key: i,
            prop: "week" + i,
            week: i + 1,
            actual_working_hours: "",
            remarks: "",
            disabled:
              new Date(this.timeList[6]).getTime() > Date.now() ? true : false,
            duedate: this.timeList[6],
          },
          { isCheckBox: true, ischecked: false, settlement_type: "否" },
          { isTotal: true, value: 0 },
        ]);
      }
    },
    proving(e, row, childIndex, parentIndex) {
      // 正数，最多一位小数的正数
      const reg = /^(([1-9]{1}\d*)|([0]{1}))(\.(\d){0,2})?$/;
      // const reg = /^(([1-9]{1}\d*))?$/;
      if (!reg.test(e.target.value)) {
        this.$message.warning(this.$i18n.t("label.field.input.number")); //"请输入最多一位小数的数字"
        this.$set(
          this.tableData[parentIndex][childIndex],
          "actual_working_hours",
          ""
        ); //输入错误清空
      }
      if (e.target.value && (e.target.value > 24 || e.target.value < 0)) {
        this.$message.warning(this.$i18n.t("label.field.input.number")); //请输入0-24之间的正整数
        this.$set(
          this.tableData[parentIndex][childIndex],
          "actual_working_hours",
          ""
        ); //输入错误清空
      }
    },
    updateSearchTab(val, index) {
      // 任务/问题字段有值时默认勾选计费
      if (val) {
        this.tableData[index][11].ischecked = true;
      } else {
        this.tableData[index][11].ischecked = false;
      }
    },
    // -----------------------远程搜索相关---------------------------------------
    // 点击前往精准搜索--选中弹出窗中的数据
    changeSelect(row) {
      this.showSearchTable = false;
      let options = [
        {
          label: row.data.name,
          value: row.data.id,
        },
      ];
      //前往精准搜索项目名称发生改变时清空上次选择的成员
      if (this.curProp === "project_name") {
        this.tableData[this.curRow][2].member = "";
        this.tableData[this.curRow][2].optionList = []; //清空成员下拉数据
        this.formData[this.curRow].member = ""; //存储在formData里的数据也清空
        this.findMember(row.data.id, this.curRow);
      }
      this.$set(
        this.tableData[this.curRow][this.curColum],
        "optionList",
        options
      ); //下拉数据
      this.$set(
        this.tableData[this.curRow][this.curColum],
        this.curProp,
        row.data.id
      ); //给绑定的值赋值
      this.$set(this.formData[this.curRow], this.curProp, row.data.id); //保存当前行的项目id
    },
    //根据项目获取当前登录人在该项目下的成员情况
    findMember(objectid, index) {
      let params = {
        projectId: objectid,
      };
      CommonObjApi.findMemberByProject(params).then((res) => {
        if (res.result && res.data && res.data.length > 0) {
          let arr = [];
          res.data.forEach((item) => {
            let obj = {};
            obj.value = item.id;
            obj.label = item.name;
            arr.push(obj);
          });
          this.$nextTick(() => {
            this.tableData[index][2].member = res.data[0].id;
            this.tableData[index][2].optionList = arr;
            this.formData[index].member = res.data[0].id;
          });
        }
      });
    },
    // 设置查找筛选条件
    setFieldReltaion(filterFieldIds) {
      let values = [];
      values.push(this.id);
      for (let reltaion in filterFieldIds) {
        let attr = this.formAttr.find((item) => {
          return item.fieldId === filterFieldIds[reltaion].fieldid;
        });
        if (
          attr !== undefined &&
          this.formData[attr.prop] !== null &&
          Array.isArray(this.formData[attr.prop])
        ) {
          values.push(this.formData[attr.prop].join(";"));
        } else if (attr !== undefined && this.formData[attr.prop] !== null) {
          values.push(this.formData[attr.prop]);
        }
      }
      this.$refs.searchTable.filterConditionVals = values;
    },
    // 远程搜索
    selectMore(params, item, formData, index, parentIndex) {
      // 查询是否有默认条件
      CommonObjApi.getLookupInfo({
        fieldId: params.fieldId,
        objId: item.objId,
        prefix: item.prefix,
      }).then((res) => {
        let values = [''];
        // 开启查找筛选
        if (res.data.isEnableFilter === "true") {
          for (let reltaion in res.data.filterFieldIds) {
            let attr = this.formAttr.find((attrItem) => {
              return (
                attrItem.fieldId === res.data.filterFieldIds[reltaion].fieldid
              );
            });
            if (
              attr !== undefined &&
              formData[attr.prop] !== null &&
              Array.isArray(formData[attr.prop])
            ) {
              values.push(formData[attr.prop].join(";"));
            } else if (attr !== undefined && formData[attr.prop] !== null) {
              values.push(formData[attr.prop]);
            }
          }
        }
        params.lkdp = JSON.stringify(values);
        CommonObjApi.queryMoreDataByName(params).then((res) => {
          let options = JSON.parse(res.data);
          options.forEach((option) => {
            option.value = option.id;
            option.label = option.queryname;
          });
          this.$set(this.tableData[parentIndex][index], "optionList", options);
        });
      });
    },
    // 远程搜索方法
    remoteMethod(query, item, index, parentIndex) {
      if (query !== "") {
        this.curColum = index; //当前操作的列
        this.curRow = parentIndex; //当前操作的行
        if (
          item.prop === "member" &&
          !this.formData[parentIndex].project_name
        ) {
          this.$message.error(
            this.$i18n.t("label.projectManagement.selectitem")
          ); //请选择项目
          return;
        }
        setTimeout(() => {
          let params = {
            name: query,
            objId: item.prefix,
            fieldId: item.fieldId,
            lkdp: "",
            page: 1,
            pageSize: 20,
            projectId: this.formData[parentIndex].project_name,
          };
          // index-列 parentIndex-行
          this.selectMore(params, item, this.formData, index, parentIndex);
        }, 200);
      }
    },

    // 直接模糊搜索时选中下拉搜索的值
    changeEvent(val, index, prop) {
      if (prop === "project_name") {
        //项目名称发生改变(点击项目名称右侧的×或者切换了其他项目名称)时清空上次选择的成员
        this.tableData[index][2].member = "";
        this.tableData[index][2].optionList = []; //清空成员下拉数据
        this.formData[index].member = ""; //存储在formData里的数据也清空
        this.findMember(val, index);
      }
      this.$set(this.formData[index], prop, val); //保存当前的项目id
    },
    // 查找/查找多选
    remoteSearch(item, parentIndex, index) {
      this.taskProblem = this.tableData[parentIndex][3].taskOrProblem;
      if (item.prop == "member") {
        if (this.formData[parentIndex].project_name == "") {
          this.$message.error(
            this.$i18n.t("label.projectManagement.selectitem")
          ); //"请选择项目"
          return;
        }
      }
      this.projectId = this.formData[parentIndex].project_name;
      this.curRow = parentIndex; //当前操作的第几行
      this.curColum = index; //当前操作的第几列
      this.curProp = item.prop; //当前操作的对象，成员或项目
      this.relevantObjApi = "";
      this.fieldId = item.fieldId;
      this.relevantObjId = item.objId;
      this.relevantPrefix = item.prefix;
      this.showSearchTable = true;
      this.checked = item.fieldType === "MR" ? true : false;
      this.$nextTick(() => {
        this.$refs.searchTable.page = 1;
        this.$refs.searchTable.pageObj.dataList = [];
        this.$refs.searchTable.searchConditions = "";
        this.$refs.searchTable.init();
      });
    },
    // 新增查找、查找多选字段
    newSearchableField(item) {
      // 先查权限再新建
      CommonObjApi.getObjectPermission({ id: item.objId }).then((res) => {
        if (res.data.add && res.data.isquickcreated) {
          // 获取对象api后进行页面跳转
          CommonObjApi.getLookupInfo({
            fieldId: item.fieldId,
            objId: item.objId,
            prefix: item.prefix,
          }).then((res) => {
            let objectApi = res.data.objectApi;
            window.open(
              `#/add-relevant-obj/${item.prefix}/${objectApi}/${this.newObjLevel}?addType=option&prop=${item.prop}`
            );
          });
        } else {
          // 抱歉，您暂无该对象新建权限
          this.$message.warning(this.$i18n.t("message.nopermession"));
        }
      });
    },
    // 批量新增-取消
    cancel() {
      this.$router.go(-1);
    },
    // 批量新增-新增/修改保存
    save(flag) {
      let valid = true;
      let dataList = [];
      let tableDataClone = _.cloneDeep(this.tableData);
      tableDataClone.forEach((item) => {
        let rowForm = [],
          rowHour = [];
        item.forEach((childItem) => {
          if (childItem.isForm) {
            if (
              !childItem.project_name &&
              !childItem.member &&
              !childItem.taskOrProblem
            ) {
              valid = false;
              this.$message.warning(
                this.$i18n.t("vue_label_lead_required_fields")
              ); //"必填项不能为空！"
            }
          }
          if (
            !childItem.isForm &&
            !childItem.isCheckBox &&
            !childItem.isTotal &&
            !childItem.isCheckDel &&
            childItem.actual_working_hours
          ) {
            delete childItem.disabled;
            delete childItem.key;
            delete childItem.prop;
            delete childItem.week;
            rowHour.push(childItem);
          }
          if (childItem.isCheckBox) {
            childItem.settlement_type = childItem.ischecked ? "是" : "否";
            delete childItem.isCheckBox;
            delete childItem.ischecked;
            rowForm.push(childItem);
          }
          if (childItem.isForm) {
            delete childItem.fieldId;
            delete childItem.isForm;
            delete childItem.prop;
            delete childItem.key;
            delete childItem.label;
            delete childItem.objId;
            delete childItem.prefix;
            delete childItem.type;
            delete childItem.optionList;
            rowForm.push(childItem);
          }
        });
        let _rowForm = Object.assign({}, ...rowForm);
        let obj = rowHour.map((item) => {
          return Object.assign({}, _rowForm, item);
        });
        dataList.push(...obj);
        if (!dataList.length) {
          this.$message.error(
            this.$i18n.t("label.projectManagement.one.work.list")
          ); //至少填写一条工作清单！
          valid = false;
        }
      });
      if (valid) {
        // return
        this.isSaveBtnLoadin = true;
        let params = {
          objectApi: "CloudccProjectActualWorkList",
          data: JSON.stringify(dataList),
          marketsea: this.pageFlag === "clientPool" ? "true" : "false", //true是公海池新建记录，false不是
        };
        CommonObjApi.save(params).then((res) => {
          if (res.data && res.data[0] && res.data[0].isSaveSuccess === "true") {
            this.recordId = res.data[0].id;
            if (this.recordId !== undefined && this.recordId !== "") {
              // 成功提示
              this.$message.success(this.$i18n.t("label.search.saveok"));
              this.isSaveBtnLoadin = false;
              // 保存并新建
              if (flag === "saveAndRenew") {
                // 初始化数据
                this.gradeDate = new Date();
                this.toWeek(this.gradeDate);
              } else {
                this.$router.push({
                  path: "/actualworklist/listviews",
                  query: {
                    objId: "projectworklist",
                  },
                });
              }
            }
          } else {
            if(res.data[0].errormessage.includes('illegal_field_apiname')){
              // 值不存在或与筛选条件不匹配
              this.$message.error(this.$i18n.t("c2420"));
            }else{
              this.$message.error(res.data[0].errormessage || this.$i18n.t("chatter.save.f"));
            }
            
          }
        });
      }
    },
    //失去焦点
    blurinput(childIndex, parentIndex) {
      let num = this.tableData[parentIndex][childIndex].actual_working_hours;
      if (num && num !== "" && num !== null) {
        let xiaoshu = num.split(".")[1];
        if ((xiaoshu && xiaoshu.length == 0) || !xiaoshu) {
          this.$set(
            this.tableData[parentIndex][childIndex],
            "actual_working_hours",
            num.split(".")[0]
          );
        }
      }
    },
    resizeCallback(offsetHeight) {
      this.dialogBodyHeight = `${offsetHeight - 248}px`;
    }
  },
  created() {
    // 初次进入页面渲染数据
    this.toWeek(this.gradeDate);
  },
  mounted() {
    Bus.$on("windowResize", this.resizeCallback);
  },
  beforeDestroy() {
    Bus.$off("windowResize", this.resizeCallback);
  },
};
</script>
<style lang="scss" scoped>
.mainPro {
  height: calc(100% - 20px);
  max-height: calc(100% - 20px);
  overflow-y: auto;
  width: calc(100% - 25px);
  background: #ffffff;
  margin: 10px;
  min-height: 500px;
  padding: 10px 20px;
  position: relative;
}
.bottomBtnBox {
  position: absolute;
  right: 10px;
  bottom: 40px;
}
.removeIcon {
  cursor: pointer;
}
.searchTipBox {
  height: 34px;
  line-height: 34px;
  cursor: pointer;
  padding: 0 20px;
  margin-bottom: 0;
  &:hover {
    background: #f5f7fa;
  }
}

.addLine {
  margin-top: 10px;
  margin-left: 35px;
}

.proMain {
  position: relative;
  font-size: 12px;
  ::v-deep .downIcon {
    margin: 0;
  }
}
.tableCon {
  div:nth-child(1) li {
    opacity: 0;
  }
  &:hover {
    div:nth-child(1) li {
      opacity: 1;
    }
  }
}
@media all and (min-width: 1200px) and (max-width: 1300px) {
  .tableConBox {
    max-height: 300px;
    overflow-y: auto;
  }
}
@media all and (min-width: 1305px) and (max-width: 1600px) {
  .tableConBox {
    max-height: 350px;
    overflow-y: auto;
  }
}
@media all and (min-width: 1601px) and (max-width: 1920px) {
  .tableConBox {
    max-height: 500px;
    overflow-y: auto;
  }
}
.topDate {
  display: flex;
  justify-content: center;
  height: 50px;
  .checkIcon {
    cursor: pointer;
    padding-top: 4px;
  }
  .weekblock {
    width: 200px;
    position: relative;
    ::v-deep .el-input__inner {
      text-align: center;
      border: none;
      height: 22px;
      line-height: 22px;
      width: 200px;
      padding: 0 0 0 20px;
      cursor: pointer;
    }
    ::v-deep .el-input__suffix {
      display: none;
    }
    ::v-deep .el-input__icon {
      height: 20px;
      line-height: 20px;
    }
    .weekDate {
      position: absolute;
      width: 100%;
      opacity: 0;
      z-index: 100;
    }
    .week-picker {
      display: flex;
      align-items: center;
      text-align: center;
      width: 100%;
    }
  }
}
.proCon {
  display: flex;
  min-width: 1300px;
  overflow: hidden;
  overflow-x: auto;
  .proList {
    flex: 1;
    ul {
      overflow: hidden;
      max-width: 100%;
      div {
        float: left;
        margin-right: 0.5%;
        &:nth-child(4),
        &:nth-child(2),
        &:nth-child(3) {
          width: 12%;
          min-width: 120px;
        }
        &:nth-child(1),
        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(8),
        &:nth-child(9),
        &:nth-child(10),
        &:nth-child(11),
        &:nth-child(12) {
          width: 6%;
          min-width: 80px;
        }
        &:nth-child(1) {
          width: 20px;
          min-width: 20px;
          text-align: center;
          li {
            line-height: 30px;
          }
        }
      }
      li {
        margin-bottom: 10px;
        position: relative;
        width: 100%;
        margin-right: 10px;
        & > span {
          display: block;
          width: 100%;
        }
        .searchRemoteTab {
          width: 100%;
        }
        &.isTotal {
          line-height: 30px;
        }
        &.removeIcon {
          font-size: 18px;
          color: #005fb2;
        }
        &.total span {
          margin-right: 20px;
        }
        &.formItem {
          // width: 140px;
          b {
            display: inline-block;
            width: 10px;
            vertical-align: top;
            color: rgb(252, 73, 73);
          }
          span {
            display: inline-block;
            width: calc(100% - 10px);
          }
          ::v-deep .el-input__inner {
            height: 30px;
            padding: 0 10px;
            line-height: 30px;
          }
          ::v-deep .el-input__icon {
            line-height: 30px;
          }
        }
        &.checkItem {
          // width: 60px;
          text-align: center;
          margin-top: 4px;
        }
        &.special-column {
          display: flex;
          align-items: center;
          // width: 100px;
          height: 30px;
          border: 1px solid #dcdfe6;
          border-radius: 4px;
          & > span {
            display: flex;
            align-items: center;
          }
          .el-input {
            height: 26px;
            line-height: 26px;
            width: 100%;
            min-width: 30px;
            margin: 0;
          }
          .popInner {
            cursor: pointer;
            padding: 0 10px;
            border-right: 1px solid #dcdfe6;
          }
          ::v-deep .el-input__inner {
            display: block;
            text-align: center;
            border: none;
            height: 26px;
            padding: 0;
            line-height: 26px;
          }
        }

        b {
          font-weight: normal;
        }
        .desc {
          margin-right: 20px;
        }
        i {
          cursor: pointer;
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      &.topHead {
        margin-bottom: 0;
        height: 30px;
        li.formItem {
          text-indent: 5px;
        }
        li.special-column {
          border: none;
          height: 20px;
        }
        li.checkItem {
          margin-top: 0;
        }
      }
    }
  }
}
</style>
