import axios from '@/config/httpConfig'

//获取以用户分组的列表视图
export function findUserIdProjectWorkList(data) {
  return axios.post('/projectWorkList/findUserIdProjectWorkList', data)
}

//获取以日期分组的列表视图 
export function findTimeBucket(data) {
  return axios.post('/projectWorkList/findTimeBucket', data)
}

//获取展示页面时间段内的时长汇总
export function getCurveTimeBucket(data) {
  return axios.post('/projectWorkList/getCurveTimeBucket', data)
}
//获取实际工作清单网格视图
export function getGridView(data) {
  return axios.post('/projectWorkList/getGridView', data)
}

// 获取查找信息
export function getLookupInfo(data) {
  return axios.post('/lookup/getLookupInfo', data)
}

// 根据名称搜索对象记录接口--查找字段自动搜索
export function queryMoreDataByName(data) {
  return axios.post('objectdetail/queryMoreDataByName', data)
}

// 获取对象权限
export function getObjectPermission(data) {
  return axios.post('objectdetail/getObjectPermission', data)
}

// 添加或更新对象记录
export function save(data) {
  return axios.post('/objectInfo/save', data)
}
//根据项目获取当前登录人在该项目下的成员情况
export function findMemberByProject(data) {
  return axios.post('/projectWorkList/findMemberByProject', data)
}
//获取对象记录权限
export function getPermissionById(data) {
  return axios.post('/objectdetail/getPermissionById', data)
}
// 删除记录
export function odelete(data) {
  return axios.post('/objectdetail/delete', data)
}

// 获取批量删除的数据
export function batchDelete(data) {
  return axios.post('/batch/batchDelete', data)
}